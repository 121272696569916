<template>
  <div class="text-center">
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="4000">
      <div class="k-hz-center-flex">
        <v-icon size="28">mdi-information</v-icon>
        <p class="k-ml-10 k-fz-16">{{ snackbar.message }}</p>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      snackbar: "snackbar/getSnackbar",
    }),
  },
};
</script>

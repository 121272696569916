import { createRouter, createWebHistory } from "vue-router";

function importView(path) {
  return () =>
    import(/* webpackChunkName: "importView" */ `../views/${path}.vue`);
}

function importLayout(path) {
  return () =>
    import(
      /* webpackChunkName: "importLayout" */ `../views/Layout/${path}.vue`
    );
}

const routes = [
  {
    path: "/403",
    name: "NotPermitted",
    component: importView("NotPermitted"),
  },
  {
    path: "/404",
    name: "NotFound",
    component: importView("NotFound"),
  },
  {
    path: "/",
    redirect: { name: "Dashboard" },
    component: importLayout("MainLayout"),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: {
          access: "dashboard",
        },
        component: importView("HomeView"),
      },
      {
        path: "/employee",
        name: "Employee",
        meta: {
          access: "employee",
        },
        component: importView("EmployeeView"),
      },
      {
        path: "/employee/:employee_id",
        name: "EmployeeHistory",
        meta: {
          access: "employee",
        },
        component: importView("EmployeeHistoryView"),
      },
      {
        path: "/attendance",
        name: "Attendance",
        meta: {
          access: "attendance",
        },
        component: importView("AttendanceView"),
      },
      {
        path: "/session",
        name: "Session",
        meta: {
          access: "session",
        },
        component: importView("SessionView"),
      },
      {
        path: "/payroll",
        name: "Payroll",
        meta: {
          access: "payroll",
        },
        component: importView("PayrollView"),
      },
      {
        path: "/job-type",
        name: "JobType",
        meta: {
          access: "job-type",
        },
        component: importView("JobTypeView"),
      },
      {
        path: "/storage",
        name: "Storage",
        meta: {
          access: "storage",
        },
        component: importView("StorageView"),
      },
      {
        path: "/storage/:storage_id",
        name: "StorageDetail",
        meta: {
          access: "storage",
        },
        component: importView("StorageDetailView"),
      },
      {
        path: "/storage/:storage_id/si/:storage_detail_id",
        name: "StorageItem",
        meta: {
          access: "storage",
        },
        component: importView("StorageItemView"),
      },
      {
        path: "/storage/:storage_id/sh/:storage_detail_id",
        name: "StorageHistory",
        meta: {
          access: "storage",
        },
        component: importView("StorageHistoryView"),
      },
      {
        path: "/role",
        name: "Role",
        meta: {
          access: "role",
        },
        component: importView("RoleView"),
      },
      {
        path: "/user",
        name: "User",
        meta: {
          access: "user",
        },
        component: importView("UserView"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: importView("LoginView"),
  },
  {
    path: "/about",
    name: "about",
    component: importView("AboutView"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "NotFound" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  if (to.name != "NotPermitted" || to.name != "NotFound") {
    if (from && !isAuthenticated() && to.name !== "Login") {
      // redirect the user to the login page
      return { name: "Login" };
    }
    const access = localStorage.getItem("hasAccess")?.split(",");
    if (!access?.find((x) => x == to.meta.access) && to?.meta?.access) {
      return { name: "NotPermitted" };
    }
  }
});

function isAuthenticated() {
  if (localStorage.getItem("tokenkey")) {
    return true;
  } else {
    return false;
  }
}

export default router;

import { uploadData } from "../method";

const initialState = () => ({
  message: null,
  status: 0,
});

const state = initialState;

const getters = {
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
};

const actions = {
  async upload({ commit }, data) {
    try {
      const response = await uploadData("/upload", data);
      commit("SET_RESPONSE", response.data);
      return response;
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  // async downloadPdf({ commit }) {
  //   try {
  //     const response = await downloadFile("/template/template");
  //     let blob = new Blob([response.data], {
  //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       }),
  //       url = window.URL.createObjectURL(blob);

  //     let a = document.createElement("a");
  //     a.href = url;
  //     a.download = url.substr(url.lastIndexOf("/") + 1);
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     commit("SET_RESPONSE", {
  //       status: "Success",
  //       message: "Berhasil mengunduh berkas",
  //     });
  //   } catch (err) {
  //     commit("SET_FAILED");
  //   }
  // },
};

const mutations = {
  SET_FAILED(state) {
    state.message = "Jaringan Bermasalah";
    state.status = 0;
  },
  SET_FAILED_MESSAGE(state, error) {
    state.message = error.data.message;
    state.status = error.data.status;
  },
  SET_RESPONSE(state, response) {
    state.message = response.message;
    state.status = response.status;
  },
  SET_DATA(state, response) {
    state.data = response.data;
    state.meta = response.meta;
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

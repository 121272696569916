import { getData, postData, putData } from "../method";
import { err } from "@/helper/errHelper";

const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
  status: null,
  deleted: "present",
};

const itemInit = {
  id: null,
  sub_id: null,
  job_type_id: null,
  name: null,
  address: null,
  gender: null,
  phone_num: null,
  salary: null,
  salary_description: null,
  job_title: null,
  cut_salary: null,
  cash_receipt_limit: null,
  acc_num: null,
  email: null,
  image: null,
  identity_card: null,
  status: null,
  bank_name: null,
  order: null,
  //forHIstory
  note: null,
};

const initialState = () => ({
  message: null,
  status: 0,
  data: [],
  meta: [],
  singleItem: {},
  item: { ...itemInit },
  dropdown: [],
  request: { ...requestInit },
});

const state = initialState;

const getters = {
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
  getData: (state) => state.data,
  getDropdown: (state) => state.dropdown,
  getMeta: (state) => state.meta,
  getItem: (state) => state.item,
  getSingleItem: (state) => state.singleItem,
};

const actions = {
  setItem({ commit }, value) {
    commit("SET_ITEM", { ...value });
  },
  resetItem({ commit }) {
    commit("SET_ITEM", { ...itemInit });
  },
  resetRequest({ commit }) {
    commit("SET_REQUEST", { ...requestInit });
  },
  async fetchData({ commit, state }) {
    try {
      const response = await getData("/employee", {
        params: {
          ...state.request,
        },
      });
      commit("SET_RESPONSE", response.data);
      commit("SET_DATA", response.data.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async fetchDropdown({ commit }) {
    try {
      const response = await getData("/employee/dropdown");
      commit("SET_RESPONSE", response.data);
      commit("SET_DROPDOWN", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async fetchItem({ commit }, id) {
    try {
      const response = await getData(`/employee/gi/${id}`);
      commit("SET_RESPONSE", response.data);
      commit("SET_SINGLE_ITEM", response.data.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async store({ commit }, data) {
    try {
      const response = await postData("/employee", data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async update({ commit }, data) {
    try {
      const response = await putData(`/employee/${data.id}`, data.data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async updateHistory({ commit }, data) {
    try {
      const response = await putData(`/employee/history`, {
        history: data.history,
      });
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async updateLimit({ commit }, limit) {
    try {
      const response = await putData(`/employee/ulcr`, limit);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async softDelete({ commit }, id) {
    try {
      const response = await putData(`/employee/sd/${id}`);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async bulkSoftDeleteV2({ commit }, data) {
    try {
      const response = await putData(`/bulkSoftDeleteV2/employee`, data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async changeStatus({ commit }, id) {
    try {
      const response = await putData(`/employee/us/${id}`);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async restore({ commit }, id) {
    try {
      const response = await putData(`/employee/r/${id}`);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async bulkRestore({ commit }, data) {
    try {
      const response = await putData(`/bulkRestore/employee`, data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
};

const mutations = {
  SET_FAILED(state) {
    state.message = "Jaringan Bermasalah";
    state.status = 0;
  },
  SET_FAILED_MESSAGE(state, error) {
    console.log(error);
    let temp = err.find((x) => x.code == error.data?.error?.code) || null;
    if (temp) {
      let temp2 = temp.type.find((y) => y.parent == "employee") || "";
      state.message = temp2.title + " " + temp.message;
    } else {
      state.message = error.data.message;
    }
    state.status = error.data.status;
  },
  SET_RESPONSE(state, response) {
    state.message = response.message;
    state.status = response.status;
  },
  SET_DATA(state, response) {
    state.data = response.data;
    state.meta = response.meta;
  },
  SET_DROPDOWN(state, response) {
    state.dropdown = response.data;
  },
  SET_DATA_DATA(state, data) {
    state.data = data;
  },
  SET_ITEM(state, value) {
    state.item = value;
  },
  SET_SINGLE_ITEM(state, value) {
    state.singleItem = value;
  },
  SET_REQUEST(state, value) {
    state.request = value;
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

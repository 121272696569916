import { getData } from "../method";

const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
  deleted: "present",
  month: null,
  year: null,
};

const itemInit = {
  id: null,
  storage_detail_id: null,
  description: null,
  by: null,
  occurrence_at: null,
  order: null,
};

const initialState = () => ({
  message: null,
  status: 0,
  data: [],
  meta: [],
  singleItem: {},
  item: { ...itemInit },
  request: { ...requestInit },
});

const state = initialState;

const getters = {
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
  getData: (state) => state.data,
  getMeta: (state) => state.meta,
  getItem: (state) => state.item,
};

const actions = {
  setItem({ commit }, value) {
    commit("SET_ITEM", { ...value });
  },
  resetItem({ commit }) {
    commit("SET_ITEM", { ...itemInit });
  },
  resetRequest({ commit }) {
    commit("SET_REQUEST", { ...requestInit });
  },
  async fetchData({ commit, state }, id) {
    try {
      const response = await getData(`/storage-history/${id}`, {
        params: {
          ...state.request,
        },
      });
      commit("SET_RESPONSE", response.data);
      commit("SET_DATA", response.data.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
};

const mutations = {
  SET_FAILED(state) {
    state.message = "Jaringan Bermasalah";
    state.status = 0;
  },
  SET_FAILED_MESSAGE(state, error) {
    state.message = error.data.message;
    state.status = error.data.status;
  },
  SET_RESPONSE(state, response) {
    state.message = response.message;
    state.status = response.status;
  },
  SET_DATA(state, response) {
    state.data = response.data;
    state.meta = response.meta;
  },
  SET_DATA_DATA(state, data) {
    state.data = data;
  },
  SET_ITEM(state, value) {
    state.item = value;
  },
  SET_REQUEST(state, value) {
    state.request = value;
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

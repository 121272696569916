<template>
  <v-app>
    <router-view />
    <snackbar-main />
  </v-app>
</template>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<script>
import SnackbarMain from "./components/SnackbarMain.vue";
export default {
  components: { SnackbarMain },
  mounted() {
    this.$store.dispatch("setIsMobile", window.innerWidth);
    this.$store.dispatch("setIsMobileHori", window.innerHeight);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.$store.dispatch("setIsMobile", window.innerWidth);
      this.$store.dispatch("setIsMobileHori", window.innerHeight);
    },
    // async gotoRoute(name){
    //   try{
    //     await this.$router.push({name})
    //   }catch(_){
    //     await this.$router.push({name: 'NotFound', })
    //   }
    // }
  },
};
</script>

import store from "@/store";

const lang = () => {
  let temp;
  setTimeout(() => {
    temp = store.state.lang || "id";
  }, 1000);
  return temp;
};
const err = [
  {
    code: "ER_DUP_ENTRY",
    message: lang == "en" ? "already used" : "sudah terpakai",
    type: [
      {
        parent: "employee",
        title: "Sub id / e-mail",
      },
      {
        parent: "user",
        title: "User name / e-mail",
      },
      {
        parent: "role",
        title: "Role name",
      },
    ],
  },
  {
    code: "ER_BAD_NULL_ERROR",
    message: "All field with *asterisk must be filled",
  },
];

export { err };

const initialState = () => ({
  snackbar: {
    color: null,
    message: "message",
    show: false,
  },
});
const state = initialState;

const getters = {
  getSnackbar: (state) => state.snackbar,
};
const actions = {
  showSnackbar({ commit }, { color, message, show = true }) {
    const value = {
      color: color,
      message: message,
      show: show,
    };
    commit("SET_SNACKBAR", value);
  },
  hideSnackbar({ commit, state }) {
    const value = {
      ...state.snackbar,
      show: false,
    };
    commit("SET_SNACKBAR", value);
  },
  resetState({ commit }) {
    commit("resetState");
  },
};

const mutations = {
  SET_SNACKBAR(state, value) {
    state.snackbar = value;
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import axios from "axios";
import store from "@/store";
import router from "@/router";

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
  },
});

const waitForStorageToBeReady = async (config) => {
  await store.restored;
  const token = localStorage.getItem("tokenkey");
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
    config.headers["x-lang"] = store.getters["getLang"];
  }
  return config;
};

http.interceptors.request.use(
  function (config) {
    waitForStorageToBeReady(config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status == 401) {
      store.dispatch("logoutStorage");
      router.go({ name: "Login" });
    }
    return Promise.reject(error);
  }
);

export default http;

/* eslint-disable prettier/prettier */
import snackbar from './modules/snackbar'
import employee from './modules/employee'
import session from './modules/session'
import fileUpload from './modules/fileUpload'
import jobType from './modules/jobType'
import attendance from './modules/attendance'
import payroll from './modules/payroll'
import storage from './modules/storage'
import storageDetail from './modules/storageDetail'
import storageItem from './modules/storageItem'
import storageHistory from './modules/storageHistory'
import employeeHistory from './modules/employeeHistory'
import role from './modules/role'
import user from './modules/user'
import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import { postData, putData } from "./method";

const authStorage = new VuexPersistence({
  key: "auth",
  storage: localStorage,
  reducer: (state) => ({ isLoggedIn: state.isLoggedIn, isMobile : state.isMobile, emailUser: state.emailUser, username: state.username, token: state.token }),
});

export default createStore({
  state: {
    isLoggedIn: !!localStorage.getItem("tokenkey"),
    isMobile: false,
    isMobileHori: false,
    isNew: false,
    token: null,
    emailUser: null,
    username: null,
    status: null,
    message: null,
    lang: "id",
    activeIndex: 0,
    popup: null,
  },
  getters: {
    getisLoggedIn: (state) => state.isLoggedIn,
    getIsMobile: (state) => state.isMobile,
    getIsMobileHori: (state) => state.isMobileHori,
    getStatus: (state) => state.status,
    getIsNew: (state) => state.isNew,
    getUsername: (state) => state.username,
    getMessage: (state) => state.message,
    getPopup: (state) => state.popup,
    getActiveIndex: (state) => state.activeIndex,
    getLang: (state) => state.lang,
  },
  mutations: {
    SET_IS_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
    SET_ACTIVE_INDEX(state, value) {
      state.activeIndex = value;
    },
    SET_LOGGED_DATA(state, value) {
      state.token = value.token;
      state.emailUser = value.data[0].email;
      state.username = value.data[0].name;
      localStorage.setItem("hasAccess", value.data[0].access);
      localStorage.setItem("tokenkey", value.token);
      localStorage.setItem("tokenexpires", value.expires_at);
    },
    SET_USERNAME(state, value) {
      state.username = value
    },
    SET_NEW(state, value) {
      state.isNew = value;
    },
    SET_POPUP(state, value) {
      state.popup = value;
    },
    SET_LANG(state, value) {
      state.lang = value;
    },
    SET_IS_MOBILE(state, value) {
      state.isMobile = value;
    },
    SET_IS_MOBILE_HORI(state, value) {
      state.isMobileHori = value;
    },
    SET_RESPONSE(state, value) {
      state.status = value.status;
      state.message = value.message;
    },
    SET_FAILED(state) {
      state.message = 'Jaringan Bermasalah'
      state.status = 0
    },
    SET_FAILED_MESSAGE(state, error) {
      state.message = error.data.message
      state.status = error.data.status
    },
    SET_RESET_DATA_LOGOUT(state) {
      state.isLoggedIn = false;
      state.emailUser = null;
      state.username = null;
      state.token = null;
    }
  },
  actions: {
    setIsMobile({ commit }, value) {
      const result = value < 800;
      commit("SET_IS_MOBILE", result);
    },
    setIsMobileHori({ commit }, value) {
      const result = value < 500;
      commit("SET_IS_MOBILE_HORI", result);
    },
    setIsLoggedIn({ commit }, value) {
      commit("SET_IS_LOGGED_IN", value);
    },
    setActiveIndex({ commit }, value) {
      commit("SET_ACTIVE_INDEX", value);
    },
    resetNew({ commit }) {
      commit("SET_NEW", false);
    },
    setPopup({commit}, value) {
      commit("SET_POPUP", value);
    },
    setLang({commit}, value) {
      commit("SET_LANG", value);
    },
    setUsername({commit}, value) {
      commit("SET_USERNAME", value);
    },
    async login({ commit }, data) {
      try{
        const response = await postData("/login", data);
        if (response.data.data?.status === 'NEW_USER') {
          commit("SET_NEW", response.data.data?.user)
        } else {
          commit("SET_LOGGED_DATA", response.data.data);
          commit("SET_IS_LOGGED_IN", true);
          commit("SET_RESPONSE", response.data);
        }
      } catch (err) {
        console.log(err);
        if (err.response) {
          commit('SET_FAILED_MESSAGE', err.response)
        } else {
          commit('SET_FAILED')
        }
      }
    },
    async changePassword({ commit }, data) {
      try{
        const response = await putData(`/user/up/${data.id}`, data.data);
        commit("SET_NEW", false);
        commit("SET_RESPONSE", response.data);
      } catch (err) {
        console.log(err);
        if (err.response) {
          commit('SET_FAILED_MESSAGE', err.response)
        } else {
          commit('SET_FAILED')
        }
      }
    },
    async logoutStorage({ commit }) {
      //miss 1 commit
      localStorage.clear();
      // await this.$store.restored;
      commit('SET_RESET_DATA_LOGOUT');
      // const initial = modules;
      //   Object.keys(initial).forEach((key) => commit(`${key}/resetState`));
    },
  },
  modules : {
    snackbar,employee,session,fileUpload,jobType,role,user,attendance,payroll,storage,storageDetail,storageItem,storageHistory,employeeHistory
  },
  plugins: [authStorage.plugin],
});

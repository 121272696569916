import { createI18n } from "vue-i18n";
import id from "@/locales/id.json";
import en from "@/locales/en.json";

export default createI18n({
  locale: process.env.VUE_APP_DEFAULT_LOCALE || "id",
  fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE || "en",
  allowComposition: true,
  messages: { id, en },
  warnHtmlInMessage: "off",
});

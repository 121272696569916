import http from "./axiosConfig";

const options = {
  headers: { "Content-Type": "multipart/form-data" },
};

const getData = (url, request) => http.get(`/ecc${url}`, request);
const postData = (url, data) => http.post(`/ecc${url}`, data);
const uploadData = (url, data) => http.post(`/ecc${url}`, data, options);
const putData = (url, data) => http.put(`/ecc${url}`, data);
const destroyData = (url) => http.delete(`/ecc${url}`);
const downloadFile = (url) =>
  http.get(url, { responseType: "blob", headers: { mode: "no-cors" } });

export { getData, postData, putData, destroyData, uploadData, downloadFile };

import { getData, putData, postData } from "../method";

const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
  month: null,
  year: null,
  deleted: "present",
  debtStatus: null,
};

const itemInit = {
  id: null,
  employee_id: null,
  total_work_hour: null,
  total_late_hour: null,
  total_overtime_hour: null,
  final_salary: null,
  total_salary: null,
  total_cut_salary: null,
  total_cash_receipt: null,
  total_tip_salary: null,
  bonus_salary: null,
  note: null,
  total_use_tip: null,
  description: null,
  date: new Date(),
  order: null,
};

const initialState = () => ({
  message: null,
  status: 0,
  data: [],
  charts: [],
  genData: [],
  dataByMonth: [],
  meta: [],
  item: { ...itemInit },
  request: { ...requestInit },
});

const state = initialState;

const getters = {
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
  getData: (state) => state.data,
  getCharts: (state) => state.charts,
  getGenData: (state) => state.genData,
  getDataByMonth: (state) => state.dataByMonth,
  getMeta: (state) => state.meta,
  getItem: (state) => state.item,
};

const actions = {
  setItem({ commit }, value) {
    commit("SET_ITEM", { ...value });
  },
  resetItem({ commit }) {
    commit("SET_ITEM", { ...itemInit });
  },
  resetRequest({ commit }) {
    commit("SET_REQUEST", { ...requestInit });
  },
  async fetchData({ commit, state }) {
    try {
      const response = await getData("/payroll", {
        params: {
          ...state.request,
        },
      });
      commit("SET_RESPONSE", response.data);
      commit("SET_DATA", response.data.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async fetchGenerateDataPurpose({ commit }, data) {
    try {
      const response = await getData("/payroll/generateDataPurpose", {
        params: {
          ...data,
        },
      });
      commit("SET_RESPONSE", response.data);
      commit("SET_GENDATA", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async getByMonth({ commit }, data) {
    try {
      const response = await getData(`/payroll/mon`, {
        params: {
          month: data.month,
          year: data.year,
          empId: data.empId || null,
        },
      });
      commit("SET_RESPONSE", response.data);
      commit("SET_MONTH_DATA", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async fetchGenerateReportPurpose({ commit }, data) {
    try {
      const response = await getData("/payroll/generateReportPurpose", {
        params: {
          ...data,
        },
      });
      commit("SET_RESPONSE", response.data);
      commit("SET_GENDATA", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async fetchChartData({ commit }, id) {
    try {
      const response = await getData(`/payroll/chart/${id}`);
      commit("SET_RESPONSE", response.data);
      commit("SET_CHARTS", response.data.data.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async generate({ commit }, data) {
    try {
      const response = await postData("/payroll", data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async bulkInsert({ commit }, data) {
    try {
      const response = await postData("/payroll/bii", data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async update({ commit }, data) {
    try {
      const response = await putData(`/payroll/${data.id}`, data.data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async updateTCR({ commit }, data) {
    try {
      const response = await putData(`/payroll/updateTCR/${data.id}`, {
        data: data.data,
      });
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async changeDebtStatus({ commit }, data) {
    try {
      let datas = {
        debt: data.debt,
        debt_status: data.debt_status,
        employee_id: data.employee_id,
        note: data.note,
        date: data.date,
        bonus: data.bonus,
      };
      const response = await putData(`/payroll/uds/${data.id}`, {
        data: datas,
        type: data.type,
      });
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async softDelete({ commit }, id) {
    try {
      const response = await putData(`/payroll/sd/${id}`);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async bulkSoftDeleteV2({ commit }, data) {
    try {
      const response = await putData(`/bulkSoftDeleteV2/payroll`, data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async restore({ commit }, id) {
    try {
      const response = await putData(`/payroll/r/${id}`);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async bulkRestore({ commit }, data) {
    try {
      const response = await putData(`/bulkRestore/payroll`, data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
};

const mutations = {
  SET_FAILED(state) {
    state.message = "Jaringan Bermasalah";
    state.status = 0;
  },
  SET_FAILED_MESSAGE(state, error) {
    state.message = error.data.message;
    state.status = error.data.status;
  },
  SET_RESPONSE(state, response) {
    state.message = response.message;
    state.status = response.status;
  },
  SET_DATA(state, response) {
    state.data = response.data;
    state.meta = response.meta;
  },
  SET_CHARTS(state, response) {
    state.charts = response;
  },
  SET_MONTH_DATA(state, response) {
    state.dataByMonth = response.data;
  },
  SET_GENDATA(state, response) {
    state.genData = response.data;
  },
  SET_DROPDOWN(state, response) {
    state.dropdown = response.data;
  },
  SET_DATA_DATA(state, data) {
    state.data = data;
  },
  SET_ITEM(state, value) {
    state.item = value;
  },
  SET_REQUEST(state, value) {
    state.request = value;
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { getData, putData, postData } from "../method";

const requestInit = {
  page: 1,
  show: 10,
  sortBy: null,
  sorting: null,
  search: null,
  deleted: "present",
  month: null,
  year: null,
};

const itemInit = {
  id: null,
  storage_detail_id: null,
  amount: null,
  deleted_amount: null,
  used_amount: null,
  remain_amount: null,
  price: null,
  bought_at: null,
  order: null,
  //note for storage history
  note: null,
};

const initialState = () => ({
  message: null,
  status: 0,
  data: [],
  data2: [],
  charts: [],
  sum: [],
  meta: [],
  singleItem: {},
  multipleItem: {},
  item: { ...itemInit },
  request: { ...requestInit },
});

const state = initialState;

const getters = {
  getMessage: (state) => state.message,
  getStatus: (state) => state.status,
  getRequest: (state) => state.request,
  getData: (state) => state.data,
  getData2: (state) => state.data2,
  getCharts: (state) => state.charts,
  getSum: (state) => state.sum,
  getMeta: (state) => state.meta,
  getItem: (state) => state.item,
  getSingleItem: (state) => state.singleItem,
  getMultipleItem: (state) => state.multipleItem,
};

const actions = {
  setItem({ commit }, value) {
    commit("SET_ITEM", { ...value });
  },
  resetItem({ commit }) {
    commit("SET_ITEM", { ...itemInit });
  },
  resetData2({ state }) {
    state.data2 = [];
  },
  resetRequest({ commit }) {
    commit("SET_REQUEST", { ...requestInit });
  },
  async fetchData({ commit, state }, id) {
    try {
      const response = await getData(`/storage-item/${id}`, {
        params: {
          ...state.request,
        },
      });
      commit("SET_RESPONSE", response.data.data);
      commit("SET_DATA", response.data.data.data);
      commit("SET_SUM", response.data.data.sum);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async fetchItem({ commit }, id) {
    try {
      const response = await getData(`/storage-item/gi/${id}`);
      commit("SET_RESPONSE", response.data);
      commit("SET_SINGLE_ITEM", response.data.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async fetchChartData({ commit }, id) {
    try {
      const response = await getData(`/storage-item/chart/${id}`);
      commit("SET_RESPONSE", response.data);
      commit("SET_CHARTS", response.data.data.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async fetchMultipleItem({ commit }, data) {
    try {
      const response = await getData(`/storage-item`, { params: { ...data } });
      commit("SET_RESPONSE", response.data);
      commit("SET_MULTIPLE", response.data.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async fetchByDate({ commit }, data) {
    try {
      const response = await getData(`/storage-item/by-date`, {
        params: { ...data },
      });
      commit("SET_RESPONSE", response.data);
      commit("SET_DATA2", response.data.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async store({ commit }, data) {
    try {
      const response = await postData("/storage-item", data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async update({ commit }, data) {
    try {
      const response = await putData(`/storage-item/${data.id}`, data.data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async updateUseOrDelete({ commit }, data) {
    try {
      const response = await putData(`/storage-item`, data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async bulkUpdate({ commit }, data) {
    try {
      const response = await putData(`/storage-item/bulk`, {
        data: data.data,
        note: data.note,
      });
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async changeStatus({ commit }, id) {
    try {
      const response = await putData(`/storage-item/us/${id}`);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async softDelete({ commit }, id) {
    try {
      const response = await putData(`/storage-item/sd/${id}`);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async bulkSoftDeleteV2({ commit }, data) {
    try {
      const response = await putData(`/bulkSoftDeleteV2/storage`, data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async restore({ commit }, id) {
    try {
      const response = await putData(`/storage-item/r/${id}`);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
  async bulkRestore({ commit }, data) {
    try {
      const response = await putData(`/bulkRestore/storage`, data);
      commit("SET_RESPONSE", response.data);
    } catch (err) {
      if (err.response) {
        commit("SET_FAILED_MESSAGE", err.response);
      } else {
        commit("SET_FAILED");
      }
    }
  },
};

const mutations = {
  SET_FAILED(state) {
    state.message = "Jaringan Bermasalah";
    state.status = 0;
  },
  SET_FAILED_MESSAGE(state, error) {
    state.message = error.data.message;
    state.status = error.data.status;
  },
  SET_RESPONSE(state, response) {
    state.message = response.message;
    state.status = response.status;
  },
  SET_DATA(state, response) {
    state.data = response.data;
    state.meta = response.meta;
  },
  SET_SUM(state, response) {
    state.sum = response;
  },
  SET_MULTIPLE(state, response) {
    state.multipleItem = response;
  },
  SET_DATA2(state, response) {
    state.data2 = response;
  },
  SET_CHARTS(state, response) {
    state.charts = response;
  },
  SET_DATA_DATA(state, data) {
    state.data = data;
  },
  SET_ITEM(state, value) {
    state.item = value;
  },
  SET_SINGLE_ITEM(state, value) {
    state.singleItem = value;
  },
  SET_REQUEST(state, value) {
    state.request = value;
  },
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
